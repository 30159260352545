
export default [
  {
    "id": 1,
    "name": "工業簿記の基礎",
    "sort": 1,
  },
  {
    "id": 2,
    "name": "材料費",
    "sort": 2,
  },
  {
    "id": 3,
    "name": "労務費",
    "sort": 3,
  },
  {
    "id": 4,
    "name": "経費",
    "sort": 4,
  },
  {
    "id": 5,
    "name": "個別原価計算と製造間接費の配賦",
    "sort": 5,
  },
  {
    "id": 6,
    "name": "部門別個別原価計算",
    "sort": 6,
  },
  {
    "id": 7,
    "name": "総合原価計算①",
    "sort": 7,
  },
  {
    "id": 8,
    "name": "総合原価計算②",
    "sort": 8,
  },
  {
    "id": 9,
    "name": "総合原価計算③",
    "sort": 9,
  },
  {
    "id": 10,
    "name": "工業簿記における財務諸表",
    "sort": 10,
  },
  {
    "id": 11,
    "name": "本社工場会計",
    "sort": 11,
  },
  {
    "id": 12,
    "name": "標準原価計算①",
    "sort": 12,
  },
  {
    "id": 13,
    "name": "標準原価計算②",
    "sort": 13,
  },
  {
    "id": 14,
    "name": "直接原価計算",
    "sort": 14,
  }
]