import Question from '@/models/question';
import axios from 'axios'
import BasicUtil from './basicUtil'
import question3 from './question_3'
import question21 from './question_2_1'
import question22 from './question_2_2'
import theme3 from './theme_3';
import theme21 from './theme_2_1';
import theme22 from './theme_2_2';
import Category from '@/models/category';

export default class HttpClient {

  private apiEndpoint: string = process.env.VUE_APP_API

  // public async saveResult(data: Result[]): Promise<void> {
  //   return axios.post(this.apiEndpoint + "/app",
  //     data,
  //     {
  //       headers: {
  //         sc: BasicUtil.getSc(),
  //       }
  //     })
  // }

  public getQuestion(): any {
    if (process.env.VUE_APP_GRADE == '3') {
      return JSON.parse(JSON.stringify(question3))
    } else if (process.env.VUE_APP_GRADE == '2-1') {
      return JSON.parse(JSON.stringify(question21))
    } else if (process.env.VUE_APP_GRADE == '2-2') {
      return JSON.parse(JSON.stringify(question22))
    }
    return JSON.parse(JSON.stringify(question3))
  }

  public getCategories(): Category[] {
    if (process.env.VUE_APP_GRADE == '3') {
      return theme3 as Category[]
    } else if (process.env.VUE_APP_GRADE == '2-1') {
      return theme21 as Category[]
    } else if (process.env.VUE_APP_GRADE == '2-2') {
      return theme22 as Category[]
    }
    return []
  }

  public getRontenQuestionsFromCategory(category: Category): Question[] {
    let questions: Question[] = this.getQuestion()
    questions = questions.filter(q => {
      return q.categoryId == category.id
    })

    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
      })
    }
    return questions
  }

  public getIncrectQuestionsFromCategory(category: Category, history: History[]): Question[] {
    let ids: string[] = []
    for (var i = 0; i < history.length; i++) {
      ids.push(history[i].i)
    }
    let questions: Question[] = this.getQuestion()
    questions = questions.filter(q => {
      return q.categoryId == category.id && ids.includes(q.id)
    })

    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
      })
    }
    return questions
  }


  public getQuestionFromId(id: string) {
    const shiken = this.getQuestion()
    const categories = this.getCategories()
    let questions: Question[] = shiken.questions
    const question = questions.find(q => {
      return q.id == id
    })
    if (question == undefined) return null
    question!.category = categories.find(c => {
      return c.id == question?.categoryId
    })
    return question
  }

  public postData(grade:string,id:string,type:number) {
    axios.post("https://nboki-api-bestlicense-p5ypmgh3pq-an.a.run.app/web-boki/" + grade + "/" + id + "/" + String(type), null, {
      headers: {
        "X-Token": "PyPV2YZB.D%hVRnBQcW~&d&rb~aN6t!u"
      }
    })
  }

  public getServerData(grade:string,id:string) {
    return axios.get("https://nboki-api-bestlicense-p5ypmgh3pq-an.a.run.app/web-boki/" + grade + "/" + id , {
      headers: {
        "X-Token": "PyPV2YZB.D%hVRnBQcW~&d&rb~aN6t!u"
      }
    })
  }
}

export class History {
  public i: string = ""
  public c: number = 0
  public d: number = 0
}